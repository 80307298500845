<template>
  <v-layout wrap>
    <v-flex xs8>
      <app-title title="Encuestas enviadas">
        <template slot="subtitle">
          Desde el
          {{ filter.dateStart | moment("DD-MMMM-YYYY") }}
          al
          {{ filter.dateEnd | moment("DD-MMMM-YYYY") }}
        </template>
      </app-title>
    </v-flex>
    <v-flex xs-4 class="text-xs-right">
      <v-btn
        color="accent"
        dark
        flat
        icon
        @click="changeToChart">
        <v-icon size="20" v-if="!chart">far fa-chart-bar</v-icon>
        <v-icon size="20" v-else>far fa-list-alt</v-icon>
      </v-btn>
      <v-menu
        v-model="menu"
        :close-on-click="false"
        :close-on-content-click="false"
        :nudge-width="100"
        offset-x>
        <v-btn
          slot="activator"
          color="grey"
          dark
          flat
          icon>
          <v-icon>more_vert</v-icon>
        </v-btn>

        <v-card>
          <v-card-text>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-dialog
                  ref="dialogStart"
                  v-model="modalDateStart"
                  :return-value.sync="filter.dateStart"
                  lazy
                  full-width
                  width="290px">
                  <v-text-field
                    slot="activator"
                    v-model="filter.dateStart"
                    label="Fecha desde"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="filter.dateStart" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="modalDateStart = false"
                      flat>
                      Volver
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.dialogStart.save(filter.dateStart)"
                      flat>
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-dialog
                  ref="dialogEnd"
                  v-model="modalDateEnd"
                  :return-value.sync="filter.dateEnd"
                  lazy
                  full-width
                  width="290px">
                  <v-text-field
                    slot="activator"
                    v-model="filter.dateEnd"
                    label="Fecha Hasta"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="filter.dateEnd" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="modalDateEnd = false"
                      flat>
                      Volver
                    </v-btn>
                    <v-btn
                    color="primary"
                    @click="$refs.dialogEnd.save(filter.dateEnd)"
                    flat>
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat @click="menu = false"> Volver</v-btn>
            <v-btn color="primary" flat @click="filterPolls()"> Filtrar </v-btn>
          </v-card-actions>
        </v-card>

      </v-menu>
    </v-flex>
    <v-flex xs12 v-if="!chart">
      <div class="loading-table" v-if="loading"></div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        class="elevation-2"
        no-data-text="No hay encuestas enviadas con las fechas ingresadas"
        hide-actions>
        <v-progress-linear
          slot="progress"
          color="primary"
          indeterminate
          v-if="loading">
        </v-progress-linear>
        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="`text-xs-${header.align}`">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr :active="props.selected">
            <td class="text-xs-left">
              {{ props.item.date_add }}
            </td>
            <td class="text-xs-left">
              {{ props.item.name }}
            </td>
            <td class="text-xs-right">
              {{ props.item.total }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-flex xs12 v-else>
      <v-card>
        <v-card-text>
          <line-chart
            :chart-data="datacollection"
            :options="optionsData"
            :style="myStyle"></line-chart>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import qs from 'qs';
import { GET_API } from '../../config';
import appTitle from '../useful/title.vue';
import LineChart from '../useful/lineChart';

export default {
  name: 'Encuestas',
  metaInfo: {
    title: 'Fechas importantes',
  },
  components: {
    appTitle,
    LineChart,
  },
  methods: {
    changeToChart() {
      this.chart = !this.chart;
    },
    filterPolls() {
      this.menu = false;
      this.getStaticsDateImportants();
    },
    getStaticsDateImportants() {
      this.polls = [];
      this.selected = [];
      this.loading = true;
      this.$http({
        method: 'post',
        url: GET_API,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({ p: 'getStaticsPolls', filters: this.filter }),
      }).then((res) => {
        this.items = res.data.json;
        this.loading = false;
        this.setFillDataOne(res.data.json);
      }).catch(() => {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' });
      });
    },
    setFillDataOne(data) {
      const labels = [];
      const labelsV = [];

      data.forEach((doc) => {
        labels.push(doc.date_add);
        labelsV.push(doc.total);
      });

      this.datacollection = {
        labels,
        datasets: [
          {
            label: 'Encuestas',
            borderColor: '#637bfe',
            backgroundColor: '#637bfe',
            showLine: true,
            spanGaps: true,
            data: labelsV,
            lineTension: 0,
            scaleStepWidth: 1,
          },
        ],
      };
    },
  },
  data() {
    return {
      items: [],
      poll: {},
      menu: false,
      dialogPoll: false,
      loading: false,
      btnSend: false,
      modalDateStart: false,
      modalDateEnd: false,
      chart: false,
      datacollection: null,
      filter: {
        dateStart: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
        dateEnd: this.$moment().format('YYYY-MM-DD'),
        type: 2,
      },
      dialog: {
        finish: false,
      },
      selected: [],
      headers: [
        { text: 'Fecha', align: 'left' },
        { text: 'Tipo', align: 'left' },
        { text: 'Valor', align: 'right' },
      ],
      optionsData: {
        hover: {
          // Overrides the global setting
          mode: 'index',
        },
        line: {
          backgroundColor: '#637bfe',
        },
        legend: {
          labels: {
            fontColor: 'grey',
            fontSize: 12,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'grey',
              fontSize: 8,
              suggestedMin: 0,
            },
          }],
          xAxes: [{
            ticks: {
              fontColor: 'grey',
              fontSize: 12,
              stepSize: 1,
              beginAtZero: true,
            },
          }],
        },
        reponsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getStaticsDateImportants();
  },
};
</script>


<style>
.v-text-field__details {
  margin-bottom: 0px;
}
.v-text-field__details .v-messages{
  min-height: 0px;
}
</style>
